.parent {

    width: 70%;
    height: 200vh;
    background-image: url('./Calendar.png');
    background-size: cover;

}

.child {
    position: absolute;
    justify-content: center;
    align-items: center;
}