.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 5s linear infinite;
}

.marquee2 span {
  animation-delay: 2.5s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

* {
  font-family: 'Space Grotesk', sans-serif;
}

.container-1 {
  display: flex;
  justify-content: space-around;
  flex-basis: 20%;
}

.container-1 div {
  border: 0.3px #3a3a3a solid;
  margin: 3px;
}