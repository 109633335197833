#gradianMN {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    
  }

  .circle {
    border-radius: 50%;
    width: 58px;
    height: 55px;
    padding: 10px;
    background: #fff;
    border: 3px solid #000;
    color: #000;
    text-align: center;
    font: 32px Arial, sans-serif;
  }